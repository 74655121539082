<template>
  <div>
    <a-button-group>
      <a-button size="small" @click="detail">详情</a-button>
      <a-popconfirm title="确定全部通过吗" :disabled="item.status === 'completed'" @confirm="approve">
        <a-button type="primary" size="small" :loading="approveLoading" :disabled="item.status === 'completed'">通过</a-button>
      </a-popconfirm>
      <a-popconfirm title="确定全部吗拒绝" :disabled="item.status === 'completed'" @confirm="reject">
        <a-button type="danger" size="small" :loading="rejectLoading" :disabled="item.status === 'completed'">拒绝</a-button>
      </a-popconfirm>
      <a-popconfirm title="确定删除吗" @confirm="destroy">
        <a-button type="danger" icon="delete" size="small">删除</a-button>
      </a-popconfirm>
    </a-button-group>
  </div>
</template>

<script>
import { sortingReportDestroy, sortingReportApprove, sortingReportReject } from "@/apis/sorting";

export default {
  props: ["item"],
  data() {
    return {
      approveLoading: false,
      rejectLoading: false,
    };
  },
  methods: {
    detail() {
      this.$router.push({ path: "/sorting/sorting_report_detail", query: { id: this.item.id } });
    },
    destroy() {
      sortingReportDestroy({ id: this.item.id }).then(() => {
        this.$message.success("删除成功");
        this.$emit("destroy", this.item);
      });
    },
    approve() {
      this.approveLoading = true;
      sortingReportApprove({ id: this.item.id })
        .then((data) => {
          this.$message.success("通过成功");
          this.$emit("update", data);
        })
        .finally(() => {
          this.approveLoading = false;
        });
    },
    reject() {
      this.rejectLoading = true;
      sortingReportReject({ id: this.item.id })
        .then((data) => {
          this.$message.success("拒绝成功");
          this.$emit("update", data);
        })
        .finally(() => {
          this.rejectLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
